import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import i18n from "./i18n.js";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */

import {
  faGlobe,
  faEarthAfrica,
  faBars,
  faXmark,
  faArrowRight,
  faUsers,
  faCircleNotch,
  faStairs,
  faCircle,
  faCity,
  faTemperatureThreeQuarters,
  faGaugeHigh,
  faGears,
  faClock,
  faCubesStacked,
  faLightbulb,
  faLeaf,
  faEye,
  faBullseye,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
/* add icons to the library */
library.add(
  faGlobe,
  faEarthAfrica,
  faBars,
  faXmark,
  faArrowRight,
  faUsers,
  faCircleNotch,
  faStairs,
  faCircle,
  faCity,
  faTemperatureThreeQuarters,
  faGaugeHigh,
  faGears,
  faClock,
  faCubesStacked,
  faLightbulb,
  faLeaf,
  faEye,
  faBullseye,
  faArrowLeft
);

// Register the FontAwesomeIcon component
const app = createApp(App);
app.component("font-awesome-icon", FontAwesomeIcon);

// Mount the Vue application
app.use(store).use(router).use(i18n).mount("#app");
