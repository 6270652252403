<template>
  <footer
    class="lg:container mx-auto xl:px-0 px-3 pb-4"
    :dir="$i18n.locale === 'ar' ? 'rtl' : 'ltr'"
  >
    <div
      class="pt-32 grid xl:grid-cols-5 md:grid-cols-2 md:grid-row-2 gap-8 place-content-between"
    >
      <div class="text-white text-lg divide-y-2 font-light">
        <h3 class="py-2 font-semibold">{{ $t("text_55") }}</h3>
        <ul class="pt-3">
          <router-link to="/">
            <li>{{ $t("text_56") }}</li>
          </router-link>
          <router-link to="about">
            <li>{{ $t("text_57") }}</li>
          </router-link>
          <router-link to="ApplyForJops">
            <li>{{ $t("text_58") }}</li>
          </router-link>
          <router-link to="ContractView">
            <li>{{ $t("text_59") }}</li>
          </router-link>
          <router-link to="NewsAndSubjects">
            <li>{{ $t("text_60") }}</li>
          </router-link>
        </ul>
      </div>
      <div class="text-white text-lg divide-y-2 font-light">
        <h3 class="py-2 font-semibold">{{ $t("text_61") }}</h3>
        <ul class="pt-3 text-[16px]">
          <router-link to="MedicalWaste">
            <li>{{ $t("text_66") }}</li>
          </router-link>
          <router-link to="InfectionControl">
            <li>{{ $t("text_65") }}</li>
          </router-link>
          <router-link to="PestControl">
            <li>{{ $t("text_63") }}</li>
          </router-link>
          <router-link to="OilWaste">
            <li>{{ $t("text_67") }}</li>
          </router-link>
          <router-link to="PharmaceuticalWaste">
            <li>{{ $t("text_69") }}</li>
          </router-link>
          <router-link to="EnvironmentalStudies">
            <li>{{ $t("text_62") }}</li>
          </router-link>
          <router-link to="TrainingDevelopment">
            <li>{{ $t("text_68") }}</li>
          </router-link>
          <router-link to="GeneralCleanliness">
            <li>{{ $t("text_64") }}</li>
          </router-link>
        </ul>
      </div>
      <div class="text-white text-lg divide-y-2 font-light">
        <h3 class="py-2 font-semibold">{{ $t("text_70") }}</h3>
        <ul class="pt-3">
          <router-link to="CollegeCleaning">
            <li>{{ $t("text_71") }}</li>
          </router-link>
          <router-link to="HarborTripole">
            <li>{{ $t("text_72") }}</li>
          </router-link>
          <router-link to="LanfillLibya">
            <li>{{ $t("text_73") }}</li>
          </router-link>
        </ul>
      </div>
      <div class="text-white text-lg divide-y-2 font-light">
        <h3 class="py-2 font-semibold">{{ $t("text_75") }}</h3>
        <ul class="pt-3 rtl:justify-end" dir="ltr">
          <li>218-91 766 1212</li>
          <li>218-91 788 1212</li>
          <li>218-91 088 1212</li>
          <li>218-21 719 4444</li>
        </ul>
      </div>
      <div class="text-white text-lg divide-y-2 font-light">
        <h3 class="py-2 font-semibold">{{ $t("text_76") }}</h3>
        <ul class="pt-3">
          <a href="">
            <li class="capitalize">{{ $t("text_77") }}</li>
          </a>
          <a href="">
            <li class="capitalize">{{ $t("text_78") }}</li>
          </a>
          <a href="">
            <li>{{ $t("text_79") }}</li>
          </a>
          <a href="">
            <li>{{ $t("text_80") }}</li>
          </a>
        </ul>
      </div>
      <div
        class="grid md:gap-0 gap-3 md:grid-cols-1 gird-cols-1 xl:col-span-3 xl:order-none order-last"
      >
        <span class="text-white py-2">
          <img
            class="md:max-w-40 max-w-32 me-auto"
            src="@/assets/logo.png"
            alt=""
          />
          <h2 class="text-2xl">{{ $t("text_81") }}</h2>
          <p class="font-light text-balance">
            {{ $t("text_82") }}
          </p>
        </span>
        <div class="flex justify-start items-end gap-2">
          <span class="flex flex-col items-center">
            <img
              class="max-w-16"
              src="@/assets/ISO/iso-2.png"
              loading="lazy"
              alt=""
            />
            <div class="text-white md:text-md text-xs pt-3">
              {{ $t("text_83") }}
            </div>
          </span>
          <span class="flex flex-col items-center">
            <img
              class="max-w-16"
              src="@/assets/ISO/iso-2.png"
              loading="lazy"
              alt=""
            />
            <div class="text-white md:text-md text-xs pt-3">
              {{ $t("text_84") }}
            </div>
          </span>
          <span class="flex flex-col items-center">
            <img
              class="max-w-16"
              src="@/assets/ISO/iso-2.png"
              loading="lazy"
              alt=""
            />
            <div class="text-white md:text-md text-xs pt-3">
              {{ $t("text_85") }}
            </div>
          </span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>
