<template>
  <div class="grid xl:grid-cols-4 xl:grid-rows-2 gap-4 grid-cols-1 py-12 px-7">
    <router-link
      to="InfectionControl"
      class="relative box-events bg-Pest-image bg-cover bg-center before:bg-black before:absolute before:w-full before:h-full before:opacity-50"
    >
      <div class="absolute text-white px-4 py-4 top-0 z-20">
        <h5 class="text-xl">{{ $t("text_47") }}</h5>
        <p class="text-xl"></p>
      </div>
    </router-link>
    <router-link
      to="MedicalWaste"
      class="relative box-events bg-Medical-image bg-cover bg-center before:bg-black before:absolute before:w-full before:h-full before:opacity-50"
    >
      <div class="absolute text-white px-4 py-4 top-0 z-20">
        <h5 class="text-xl">{{ $t("text_48") }}</h5>
        <p class="text-xl"></p>
      </div>
    </router-link>
    <router-link
      to="PestControl"
      class="relative box-events bg-news-image-one bg-cover bg-center before:bg-black before:absolute before:w-full before:h-full before:opacity-50"
    >
      <div class="absolute text-white px-4 py-4 top-0 z-20">
        <h5 class="text-xl">{{ $t("text_49") }}</h5>
        <p class="text-xl"></p>
      </div>
    </router-link>
    <router-link
      to="PharmaceuticalWaste"
      class="relative bg-pharmace-image box-events bg-cover bg-center before:bg-black before:absolute before:w-full before:h-full before:opacity-50"
    >
      <div class="absolute text-white px-4 py-4 top-0 z-20">
        <h5 class="text-xl">{{ $t("text_50") }}</h5>
        <p class="text-xl"></p>
      </div>
    </router-link>
    <router-link
      to="OilWaste"
      class="relative box-events bg-petrol-image bg-cover bg-center before:bg-black before:absolute before:w-full before:h-full before:opacity-50"
    >
      <div class="absolute text-white px-4 py-4 top-0 z-20">
        <h5 class="text-xl">{{ $t("text_51") }}</h5>
        <p class="text-xl"></p>
      </div>
    </router-link>
    <router-link
      to="TrainingDevelopment"
      class="relative box-events bg-trining-image bg-cover bg-center before:bg-black before:absolute before:w-full before:h-full before:opacity-50"
    >
      <div class="absolute text-white px-4 py-4 top-0 z-20">
        <h5 class="text-xl">{{ $t("text_52") }}</h5>
        <p class="text-xl"></p>
      </div>
    </router-link>
    <router-link
      to="EnvironmentalStudies"
      class="relative box-events bg-study-1 bg-left bg-cover before:bg-black before:absolute before:w-full before:h-full before:opacity-50"
    >
      <div class="absolute text-white px-4 py-4 top-0 z-20">
        <h5 class="text-xl">{{ $t("text_53") }}</h5>
        <p class="text-xl"></p>
      </div>
    </router-link>
    <router-link
      to="GeneralCleanliness"
      class="relative bg-coffee-image box-events bg-cover bg-center before:bg-black before:absolute before:w-full before:h-full before:opacity-50"
    >
      <div class="absolute text-white px-4 py-4 top-0 z-20">
        <h5 class="text-xl">{{ $t("text_54") }}</h5>
        <p class="text-xl"></p>
      </div>
    </router-link>
    <router-link
      to="AsbestosMcep"
      class="relative bg-Asbestos-Mcep box-events bg-cover bg-center before:bg-black before:absolute before:w-full before:h-full before:opacity-50"
    >
      <div class="absolute text-white px-4 py-4 top-0 z-20">
        <h5 class="text-xl">{{ $t("text_234") }}</h5>
        <p class="text-xl"></p>
      </div>
    </router-link>
  </div>
</template>

<style>
/* start events and action */
@media (min-width: 1280px) {
  .box-events {
    min-height: 300px;
    min-width: 300px;
    position: relative;
  }
}

@media (max-width: 1279px) {
  .box-events {
    min-height: 300px;
    min-width: 250px;
  }
}
/* end events and action */
</style>
