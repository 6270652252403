import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "Home",
    },
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    meta: {
      title: "About",
    },
  },
  {
    path: "/NewsAndSubjects",
    name: "NewsAndSubjects",
    component: () =>
      import(
        /* webpackChunkName: "newsAndSubjects" */ "../views/NewsAndSubjects.vue"
      ),
    meta: {
      title: "News and Subjects",
    },
  },
  {
    path: "/ContractView",
    name: "ContractView",
    component: () =>
      import(
        /* webpackChunkName: "contractView" */ "../views/ContractView.vue"
      ),
    meta: {
      title: "Contract View",
    },
  },
  {
    path: "/ApplyForJops",
    name: "ApplyForJops",
    component: () =>
      import(
        /* webpackChunkName: "applyForJops" */ "../views/ApplyForJops.vue"
      ),
    meta: {
      title: "Apply for Jobs",
    },
  },
  // Management Pages
  {
    path: "/EnvironmentalStudies",
    name: "EnvironmentalStudies",
    component: () =>
      import(
        /* webpackChunkName: "environmentalStudies" */ "../views/Management/EnvironmentalStudies.vue"
      ),
    meta: {
      title: "Environmental Studies",
    },
  },
  {
    path: "/PestControl",
    name: "PestControl",
    component: () =>
      import(
        /* webpackChunkName: "pestControl" */ "../views/Management/PestControl.vue"
      ),
    meta: {
      title: "Pest Control",
    },
  },
  {
    path: "/GeneralCleanliness",
    name: "GeneralCleanliness",
    component: () =>
      import(
        /* webpackChunkName: "generalCleanliness" */ "../views/Management/GeneralCleanliness.vue"
      ),
    meta: {
      title: "General Cleanliness",
    },
  },
  {
    path: "/InfectionControl",
    name: "InfectionControl",
    component: () =>
      import(
        /* webpackChunkName: "infectionControl" */ "../views/Management/InfectionControl.vue"
      ),
    meta: {
      title: "Infection Control",
    },
  },
  {
    path: "/MedicalWaste",
    name: "MedicalWaste",
    component: () =>
      import(
        /* webpackChunkName: "medicalWaste" */ "../views/Management/MedicalWaste.vue"
      ),
    meta: {
      title: "Medical Waste",
    },
  },
  {
    path: "/OilWaste",
    name: "OilWaste",
    component: () =>
      import(
        /* webpackChunkName: "oilWaste" */ "../views/Management/OilWaste.vue"
      ),
    meta: {
      title: "Oil Waste",
    },
  },
  {
    path: "/TrainingDevelopment",
    name: "TrainingDevelopment",
    component: () =>
      import(
        /* webpackChunkName: "trainingDevelopment" */ "../views/Management/TrainingDevelopment.vue"
      ),
    meta: {
      title: "Training and Development",
    },
  },
  {
    path: "/PharmaceuticalWaste",
    name: "PharmaceuticalWaste",
    component: () =>
      import(
        /* webpackChunkName: "pharmaceuticalWaste" */ "../views/Management/PharmaceuticalWaste.vue"
      ),
    meta: {
      title: "Pharmaceutical Waste",
    },
  },
  // News Pages
  {
    path: "/CollegeCleaning",
    name: "CollegeCleaning",
    component: () =>
      import(
        /* webpackChunkName: "collegeCleaning" */ "../views/News/CollegeCleaning.vue"
      ),
    meta: {
      title: "College Cleaning",
    },
  },
  {
    path: "/HarborTripole",
    name: "HarborTripole",
    component: () =>
      import(
        /* webpackChunkName: "harborTripole" */ "../views/News/HarborTripole.vue"
      ),
    meta: {
      title: "Harbor Tripole",
    },
  },
  {
    path: "/LanfillLibya",
    name: "LanfillLibya",
    component: () =>
      import(
        /* webpackChunkName: "lanfillLibya" */ "../views/News/LanfillLibya.vue"
      ),
    meta: {
      title: "Lanfill Libya",
    },
  },
  {
    path: "/ArabPharmacistsUnion",
    name: "ArabPharmacistsUnion",
    component: () =>
      import(
        /* webpackChunkName: "arabPharmacistsUnion" */ "../views/News/ArabPharmacistsUnion.vue"
      ),
    meta: {
      title: "Arab Pharmacists Union",
    },
  },
  {
    path: "/DarnaCity",
    name: "DarnaCity",
    component: () =>
      import(/* webpackChunkName: "darnaCity" */ "../views/News/DarnaCity.vue"),
    meta: {
      title: "Darna City",
    },
  },
  {
    path: "/TurkishVist",
    name: "TurkishVist",
    component: () =>
      import(
        /* webpackChunkName: "TurkishVist" */ "../views/News/visiting/TurkishVist.vue"
      ),
    meta: {
      title: "TurkishVist",
    },
  },
  {
    path: "/SabrathaUniversity",
    name: "SabrathaUniversity",
    component: () =>
      import(
        /* webpackChunkName: "SabratUniversity" */ "../views/News/visiting/SabrathaUniversity.vue"
      ),
    meta: {
      title: "SabrathaUniversity",
    },
  },
  {
    path: "/ItalianVist",
    name: "ItalianVist",
    component: () =>
      import(
        /* webpackChunkName: "ItalianVist" */ "../views/News/visiting/ItalianVist.vue"
      ),
    meta: {
      title: "ItalianVist",
    },
  },
  {
    path: "/LrclY",
    name: "LrclY",
    component: () =>
      import(
        /* webpackChunkName: "LrclY" */ "../views/Management/OurTraining/LrclY.vue"
      ),
    meta: {
      title: "LrclY",
    },
  },
  {
    path: "/TSDTraining",
    name: "TSDTraining",
    component: () =>
      import(
        /* webpackChunkName: "TSDTraining" */ "../views/Management/OurTraining/TSDTraining.vue"
      ),
    meta: {
      title: "TSDTraining",
    },
  },
  {
    path: "/SolidWasteSeparation",
    name: "SolidWasteSeparation",
    component: () =>
      import(
        /* webpackChunkName: "TSDTraining" */ "../views/News/SolidWasteSeparation.vue"
      ),
    meta: {
      title: "SolidWasteSeparation",
    },
  },
  {
    path: "/GreenView",
    name: "GreenView",
    component: () =>
      import(/* webpackChunkName: "GreenView" */ "../views/News/GreenView.vue"),
    meta: {
      title: "Green View",
    },
  },
  {
    path: "/WarkAmaq",
    name: "WarkAmaq",
    component: () =>
      import(/* webpackChunkName: "WarkAmaq" */ "../views/News/WarkAmaq.vue"),
    meta: {
      title: "WarkAmaq",
    },
  },
  {
    path: "/WorkAmaq2",
    name: "WorkAmaq2",
    component: () =>
      import(/* webpackChunkName: "WarkAmaq" */ "../views/News/WorkAmaq2.vue"),
    meta: {
      title: "WarkAmaq",
    },
  },
  {
    path: "/WorkAmaq2",
    name: "WorkAmaq2",
    component: () =>
      import(/* webpackChunkName: "WarkAmaq" */ "../views/News/WorkAmaq2.vue"),
    meta: {
      title: "WarkAmaq",
    },
  },
  {
    path: "/AsbestosMcep",
    name: "AsbestosMcep",
    component: () =>
      import(
        /* webpackChunkName: "AsbestosMcep" */ "../views/Management/AsbestosMcep.vue"
      ),
    meta: {
      title: "AsbestosMcep",
    },
  },
  {
    path: "/ElectronicWaste",
    name: "ElectronicWaste",
    component: () =>
      import(
        /* webpackChunkName: "ElectronicWaste" */ "../views/Management/ElectronicWaste.vue"
      ),
    meta: {
      title: "ElectronicWaste",
    },
  },
  {
    path: "/ChemicalWaste",
    name: "ChemicalWaste",
    component: () =>
      import(
        /* webpackChunkName: "ChemicalWaste" */ "../views/Management/ChemicalWaste.vue"
      ),
    meta: {
      title: "ChemicalWaste",
    },
  },
  {
    path: "/TermomeccanicaVisit",
    name: "TermomeccanicaVisit",
    component: () =>
      import(
        /* webpackChunkName: "TermomeccanicaVisit" */ "../views/News/visiting/TermomeccanicaVisit.vue"
      ),
    meta: {
      title: "TermomeccanicaVisit",
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const baseTitle = "MCEP";
  const pageTitle = to.meta.title;
  document.title = pageTitle ? `${baseTitle} | ${pageTitle}` : baseTitle;
  next();
});

export default router;
