import { createI18n } from "vue-i18n";
import en from "./locales/en.json";
import ar from "./locales/ar.json";

function loadLocaleMessages() {
  const locales = { en, ar };
  return locales;
}

const savedLocale = localStorage.getItem("locale") || "en";

const i18n = createI18n({
  locale: savedLocale,
  fallbackLocale: "en",
  messages: loadLocaleMessages(),
});

export default i18n;
