<template>
  <nav
    class="lg:container mx-auto text-white mb-4"
    :dir="$i18n.locale === 'ar' ? 'rtl' : 'ltr'"
  >
    <div
      class="flex relative justify-between items-center h-16 px-3 before-line"
    >
      <div class="lg:w-80 w-32 pt-2">
        <router-link to="/">
          <img class="lg:p-2 p-0" src="@/assets/logo.png" alt="Logo" />
        </router-link>
      </div>
      <font-awesome-icon
        id="barMenu"
        class="text-white lg:hidden text-lg"
        :icon="['fas', 'bars']"
        @click="showMenu"
      />
      <div
        ref="menuLinks"
        class="lg:flex lg:justify-end lg:h-auto lg:flex-row lg:text-white lg:static lg:bg-transparent absolute flex-col px-4 w-full h-screen top-0 bg-white text-black menu-links lg:z-auto z-20"
      >
        <div class="lg:hidden flex justify-end items-center my-6 z-10">
          <div class="mx-6">
            <font-awesome-icon
              class="text-white hidden text-xl mr-2"
              :icon="['fas', 'globe']"
            />
            <button @click="changeLocale('en')" class="text-xs mx-2 z-10">
              <span>{{ $t("text_1") }}</span>
            </button>
            <button @click="changeLocale('ar')" class="text-xs z-10">
              <span>{{ $t("text_2") }}</span>
            </button>
          </div>
          <div class="z-10">
            <button class="text-3xl" @click="closeMenu">
              {{ $t("text_3") }}
            </button>
          </div>
        </div>
        <div
          class="lg:hidden pb-6 relative w-full flex flex-col line-divider z-10"
        >
          <div class="mx-3 font-light text-xs uppercase">
            {{ $t("text_4") }}
          </div>
          <div class="flex relative items-center -left-[15px] rtl:right-[0]">
            <span
              style="
                width: 25px;
                height: 2px;
                position: absolute;
                background-color: red;
              "
            ></span>
            <span class="uppercase text-lg text-blue-500 font-semibold mx-8">
              {{ $t("text_5") }}
            </span>
          </div>
        </div>
        <!-- start up menu -->
        <div class="flex lg:rtl:flex-row lg:flex-row flex-col">
          <div class="my-6 px-4 mega-menu">
            <router-link
              @click="closeMenu"
              to="/"
              class="cursor-pointer flex justify-between items-center w-full mega-button"
            >
              <div class="text-lg">{{ $t("text_6") }}</div>
              <font-awesome-icon
                class="text-red-500 lg:hidden"
                :icon="['fas', 'arrow-right']"
              />
            </router-link>
          </div>
          <div class="my-6 px-4 mega-menu">
            <router-link
              @click="closeMenu"
              to="/about"
              class="cursor-pointer flex justify-between items-center w-full mega-button"
            >
              <div class="text-lg">{{ $t("text_7") }}</div>
              <font-awesome-icon
                class="text-red-500 lg:hidden"
                :icon="['fas', 'arrow-right']"
              />
            </router-link>
          </div>
          <div class="my-6 px-4 mega-menu lg:hidden block">
            <router-link
              @click="closeMenu"
              to="/NewsAndSubjects"
              class="cursor-pointer flex justify-between items-center w-full mega-button"
            >
              <div class="text-lg">{{ $t("text_60") }}</div>
              <font-awesome-icon
                class="text-red-500 lg:hidden"
                :icon="['fas', 'arrow-right']"
              />
            </router-link>
          </div>
        </div>
        <!-- end up menu -->
        <div class="lg:flex items-center relative mr-6 px-2 h-100 hidden">
          <div
            class="absolute bottom-0 left-0 rtl:right-0"
            style="
              width: 1px;
              top: 20%;
              height: 65%;
              margin: 0;
              background-color: white;
              opacity: 0.3;
              transform: rotate(13.4deg);
            "
          ></div>
          <div class="ml-6 items-center flex align-middle">
            <font-awesome-icon
              class="text-white mr-2 mb-1 text-sm font-light"
              :icon="['fas', 'globe']"
            />
            <button @click="changeLocale('en')">
              <span class="text-xs mx-2">{{ $t("text_1") }}</span>
            </button>
            <button @click="changeLocale('ar')">
              <span class="text-xs">{{ $t("text_2") }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- ------- -->
    <div class="lg:flex lg:pt-2 my-2 hidden">
      <router-link to="/NewsAndSubjects" class="mr-6">
        <div class="text-lg font-light capitalize">
          {{ $t("text_8") }}
        </div>
      </router-link>
      <router-link to="/about" class="mr-6">
        <div class="text-lg font-light capitalize">
          {{ $t("text_9") }}
        </div>
      </router-link>
    </div>
  </nav>
</template>

<style>
@media (min-width: 1025px) {
  .mega-menu > div {
    top: calc(100% + 10px);
  }

  .mega-menu > div {
    left: 0;
    opacity: 0;
    z-index: -1;
    transition: 0.3s;
  }

  .mega-menu:hover > div {
    left: 0;
    top: 100%;
    opacity: 100;
    z-index: 2;
  }
}

.news-color {
  background-color: #5e6a71;
}

/* header line  */
.before-line::after {
  content: " ";
  position: absolute;
  left: 0;
  background-color: rgb(156 163 175);
  width: 100%;
  height: 2px;
  top: calc(100% + 10px);
}

@media (max-width: 1024px) {
  .before-line::after {
    display: none;
  }
}
</style>

<script>
export default {
  data() {
    return {
      locale: this.$i18n.locale,
    };
  },
  mounted() {
    // Listen for scroll events
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    // Remove the scroll event listener when component is destroyed
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    changeLocale(locale) {
      this.locale = locale;
      this.$i18n.locale = locale;
      localStorage.setItem("locale", locale);
    },
    showMenu() {
      this.$refs.menuLinks.style.left = "0";
    },
    closeMenu() {
      this.$refs.menuLinks.style.left = "-100%";
    },
    handleScroll() {
      // Check if menu is open and user scrolls down, then close the menu
      if (this.$refs.menuLinks.style.left === "0") {
        this.closeMenu();
      }
    },
  },
};
</script>
