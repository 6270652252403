<template>
  <div id="default-carousel" class="relative mx-auto" data-carousel="slide">
    <!-- Carousel wrapper -->
    <div class="box-fliter mx-auto relative overflow-hidden md:h-[35rem] h-96">
      <!-- Carousel items -->
      <div
        v-for="(item, index) in carouselItems"
        :key="index"
        :class="{
          hidden: currentIndex !== index,
          'duration-700 ease-in-out': true,
        }"
        data-carousel-item
      >
        <img
          loading="lazy"
          :src="item.src"
          class="absolute block md:scale-100 scale-[2.2] -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"
          :alt="item.alt"
        />
        <div
          class="box absolute md:top-1/4 top-1/3 left-0 rtl:right-0 md:w-2/4 text-white z-10 -translate-y-2/4 py-8 px-2 my-2"
          :class="{ 'slide-in': !item.hidden }"
        >
          <h2 class="font-semibold text-xl">{{ item.head }}</h2>
          <p :class="{ 'slide-in': !item.hidden }">
            {{ $t(`text_${10 + index}`) }}
          </p>
        </div>
        <div class="absolute h-full top-3/4">
          <div class="flex justify-center items-center mx-2 mt-4">
            <font-awesome-icon
              v-for="(indicator, i) in carouselItems.length"
              :key="i"
              :class="{
                'text-blue-400': currentIndex === i,
                'text-gray-500': currentIndex !== i,
              }"
              :icon="['fas', 'circle']"
              class="mx-1"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Slider indicators -->
    <div
      class="absolute z-30 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3 rtl:space-x-reverse"
    ></div>
    <!-- Slider controls -->
  </div>
</template>
<style>
.box-fliter::before {
  position: absolute;
  content: " ";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(27, 27, 27, 0.253);
  z-index: 1;
}

.box {
  position: absolute;
  content: " ";
  top: 0;
  left: 0;
  width: fit-content;
  height: fit-content;
  background-color: rgba(27, 27, 27, 0.541);
  z-index: -1;
}
.slide-in {
  animation: slideIn 1.5s ease forwards;
}
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>
<script>
export default {
  data() {
    return {
      currentIndex: 0,
      carouselItems: [
        {
          src: require("@/assets/news/harbor.jpg"),
          alt: "...",
          hidden: false,
        },
        {
          src: require("@/assets/news/darna.jpg"),
          alt: "...",
          hidden: true,
        },
        {
          src: require("@/assets/news/Harbortripole/5.jpg"),
          alt: "...",
          hidden: true,
        },
      ],
    };
  },
  mounted() {
    setInterval(() => {
      const newIndex = (this.currentIndex + 1) % this.carouselItems.length;
      this.carouselItems[this.currentIndex].hidden = true;
      this.carouselItems[newIndex].hidden = false;
      this.currentIndex = newIndex;
    }, 30000);
  },
};
</script>
