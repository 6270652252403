<template>
  <div class="home">
    <div class="bg-zinc-100 lg:lg:container -z-10 mx-auto">
      <MianSlider />
      <!-- start video section -->
      <div class="relative mt-2" id="video">
        <div class="overflow-hidden md:h-64 sm:h-32 video-section -z-10">
          <video
            autoplay
            muted
            loop
            class="w-full"
            src="../assets/background.mp4"
          ></video>
        </div>

        <div
          class="bg-white max-w-5xl grid gap-4 xl:absolute top-4/6 left-1/2 xl:-translate-y-1/2 xl:-translate-x-1/2 px-8 pt-8 pb-2 mx-auto white-box"
        >
          <div class="text-center">
            <h3 class="text-xl mb-6 font-bold tracking-wider text-blue-500">
              {{ $t("text_13") }}
            </h3>
            <p class="mb-4 text-lg light">{{ $t("text_14") }}</p>
            <div class="button my-auto rel">
              <div class="buttom-lg:container w-fit flex items-center mx-auto">
                <RouterLink
                  class="capitalize p-1 text-lg hover:text-blue-400"
                  to="about"
                  >{{ $t("text_15") }}</RouterLink
                >
                <font-awesome-icon
                  class="text-blue-500 icon px-2"
                  :icon="['fas', 'arrow-right']"
                />
              </div>
            </div>
          </div>
          <!-- <div class="my-auto">
            <div class="grid md:grid-cols-2 grid-cols-1 gap-0 grid-rows-3">
              <div class="flex items-center m-3">
                <font-awesome-icon
                  class="text-blue-400 mb-2 text-xl p-2"
                  :icon="['fas', 'city']"
                />
                <span>
                  <p class="m-0 text-lg">{{ $t("text_16") }}</p>
                  <p class="mb-2 light">{{ $t("text_17") }}</p>
                </span>
              </div>
              <div class="flex items-center m-3">
                <font-awesome-icon
                  class="text-blue-400 mb-2 text-xl p-2"
                  :icon="['fas', 'temperature-three-quarters']"
                />
                <span>
                  <p class="m-0 text-xl">{{ $t("text_18") }}</p>
                  <p class="mb-2 light">{{ $t("text_19") }}</p>
                </span>
              </div>
              <div class="flex items-center m-3">
                <font-awesome-icon
                  class="text-blue-400 mb-2 text-xl p-2"
                  :icon="['fas', 'gauge-high']"
                />
                <span>
                  <p class="m-0 text-xl">{{ $t("text_20") }}</p>
                  <p class="mb-2 light">{{ $t("text_21") }}</p>
                </span>
              </div>
              <div class="flex items-center m-3">
                <font-awesome-icon
                  class="text-blue-400 mb-2 text-xl p-2"
                  :icon="['fas', 'gears']"
                />
                <span>
                  <p class="m-0 text-xl">{{ $t("text_22") }}</p>
                  <p class="mb-2 light">{{ $t("text_23") }}</p>
                </span>
              </div>
              <div class="flex items-center m-3">
                <font-awesome-icon
                  class="text-blue-400 mb-2 text-xl p-2"
                  :icon="['fas', 'clock']"
                />
                <span>
                  <p class="m-0 text-xl">{{ $t("text_24") }}</p>
                  <p class="mb-2 light">{{ $t("text_25") }}</p>
                </span>
              </div>
              <div class="flex items-center m-3">
                <font-awesome-icon
                  class="text-blue-400 mb-2 text-xl p-2"
                  :icon="['fas', 'cubes-stacked']"
                />
                <span>
                  <p class="m-0 text-xl">{{ $t("text_26") }}</p>
                  <p class="mb-2 light">{{ $t("text_27") }}</p>
                </span>
              </div>
            </div>
          </div> -->
        </div>
      </div>
      <!-- end video section -->
      <!--start part Apply-now section -->
      <div>
        <div id="Apply-now" class="md:pt-64 md:pb-16 py-4">
          <div
            class="part w-5/6 text-white p-4 mx-auto bg-violet-800 bg-cover bg-hero-pattern"
          >
            <div class="w-5/6 py-5 text-center mx-auto">
              <p class="text-2xl font-light">{{ $t("text_28") }}</p>
              <div class="button w-fit pt-6 mx-auto">
                <div class="buttom-lg:container w-fit flex items-center">
                  <router-link
                    to="ContractView"
                    class="capitalize p-1 text-xl hover:text-blue-400"
                    >{{ $t("text_29") }}</router-link
                  >
                  <font-awesome-icon
                    class="text-blue-500 icon px-2"
                    :icon="['fas', `arrow-right`]"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="part w-5/6 text-white mt-16 p-8 mx-auto bg-slate-800 bg-cover bg-hero-pattern"
          >
            <div class="w-5/6 py-5 text-center mx-auto">
              <p class="text-2xl font-light">{{ $t("text_30") }}</p>
              <div class="buttom-lg:container w-fit pt-6 mx-auto">
                <router-link
                  to="ApplyForJops"
                  class="capitalize p-1 text-xl hover:text-blue-400"
                  >{{ $t("text_29") }}</router-link
                >
                <font-awesome-icon
                  class="text-blue-500"
                  :icon="['fas', 'arrow-right']"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end part Apply-now section -->
      <!-- start blurb section -->
      <div class="blurb mb-24">
        <div
          class="grid gap-4 xl:grid-cols-3 md:grid-cols-2 grid-cols-1 xl:grid-rows-none md:grid-rows-2 grid-rows-none justify-items-center"
        >
          <router-link
            to="CollegeCleaning"
            class="bg-College-cleaning bg-right relative lg:my-0 my-4 text-white bg-cover size-80 before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-black before:opacity-50 before:hover:opacity-0 before:duration-300"
          >
            <div class="flex flex-col justify-between h-full z-20 absolute">
              <h6 class="uppercase p-4">{{ $t("text_31") }}</h6>
              <p class="p-4 text-xl">{{ $t("text_202") }}</p>
            </div>
          </router-link>
          <router-link
            to="DarnaCity"
            class="bg-news-image-one relative lg:my-0 my-4 text-white bg-cover size-80 before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-black before:opacity-50 before:hover:opacity-0 before:duration-300"
          >
            <div class="flex flex-col justify-between h-full z-20 absolute">
              <h6 class="uppercase p-4">{{ $t("text_31") }}</h6>
              <p class="p-4 text-xl">{{ $t("text_195") }}</p>
            </div>
          </router-link>
          <router-link
            to="HarborTripole"
            class="bg-buril- bg-center relative lg:my-0 my-4 text-white bg-cover size-80 before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-black before:opacity-50 before:hover:opacity-0 before:duration-300"
          >
            <div class="flex flex-col justify-between h-full z-20 absolute">
              <h6 class="uppercase p-4">{{ $t("text_31") }}</h6>
              <p class="p-4 text-xl">{{ $t("text_206") }}</p>
            </div>
          </router-link>
        </div>
        <router-link
          to="NewsAndSubjects"
          class="buttom-lg:container w-fit pt-6 mx-auto block"
        >
          <span class="capitalize p-1 text-xl">{{ $t("text_35") }}</span>
          <font-awesome-icon
            class="text-blue-500"
            :icon="['fas', 'arrow-right']"
          />
        </router-link>
      </div>
      <!-- end blurb section -->
      <!-- start news section -->
      <div
        class="flex 2xl:flex-row flex-col items-center 2xl:p-0 p-4 bg-focus-pattern bg-right bg-no-repeat bg-slate-200"
      >
        <div
          class="m-0 relative 2xl:w-5/12 overflow-hidden h-75vh mb-2 before:bg-black before:absolute before:w-full before:h-full before:opacity-35"
        >
          <img class="" src="../assets/svg/back.jpg" alt="" loading="lazy" />
          <div class="absolute text-blue-400 top-4 right-4 text-7xl">
            <h4 class="uppercase">mcep</h4>
          </div>
        </div>
        <div
          class="2xl:w-7/12 w-full grid md:grid-cols-2 2xl:grid-row-2 rid-cgols-1 gap-8 justify-items-center mx-4"
        >
          <div class="size-80 sm:size-96 bg-white rounded-tl-2xl">
            <div
              class="bg-harbor-image bg-cover bg-center h-3/6 rounded-tl-2xl opacity-100"
            >
              <font-awesome-icon
                class="text-white p-4"
                :icon="['fas', 'circle-notch']"
              />
            </div>
            <div class="p-4 h-3/6">
              <div class="flex items-center mb-4">
                <font-awesome-icon :icon="['fas', 'lightbulb']" />
                <div class="px-2 text-lg">{{ $t("text_39") }}</div>
              </div>
              <p class="lg:lg:text-md text-sm font-light">
                {{ $t("text_40") }}
              </p>
            </div>
          </div>
          <div class="size-80 sm:size-96 bg-white rounded-tl-2xl">
            <div
              class="bg-Innovation-1 bg-cover bg-center h-3/6 rounded-tl-2xl opacity-100"
            >
              <font-awesome-icon
                class="text-white p-4"
                :icon="['fas', 'circle-notch']"
              />
            </div>
            <div class="p-4 h-3/6">
              <div class="flex items-center mb-4">
                <font-awesome-icon :icon="['fas', 'leaf']" />
                <div class="px-2 text-lg">{{ $t("text_41") }}</div>
              </div>
              <p class="lg:text-md text-sm font-light">{{ $t("text_42") }}</p>
            </div>
          </div>
          <div class="size-80 sm:size-96 bg-white rounded-tl-2xl">
            <div
              class="bg-lap-image bg-cover bg-center h-3/6 rounded-tl-2xl opacity-100"
            >
              <font-awesome-icon
                class="text-white p-4"
                :icon="['fas', 'circle-notch']"
              />
            </div>
            <div class="p-4 h-3/6">
              <div class="flex items-center mb-4">
                <font-awesome-icon :icon="['fas', 'eye']" />
                <div class="px-2 text-lg">{{ $t("text_43") }}</div>
              </div>
              <p class="lg:text-md text-sm font-light">{{ $t("text_44") }}</p>
            </div>
          </div>
          <div class="size-80 sm:size-96 bg-white rounded-tl-2xl">
            <div
              class="bg-news-image-one bg-cover bg-center h-3/6 rounded-tl-2xl opacity-100"
            >
              <font-awesome-icon
                class="text-white p-4"
                :icon="['fas', 'circle-notch']"
              />
            </div>
            <div class="p-4 h-3/6">
              <div class="flex items-center mb-4">
                <font-awesome-icon :icon="['fas', 'bullseye']" />
                <div class="px-2 text-lg">{{ $t("text_45") }}</div>
              </div>
              <p class="lg:text-md text-sm font-light">{{ $t("text_46") }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- end news section -->
      <ManaGements />
    </div>
  </div>
</template>
<style>
@media (min-width: 1025px) {
  .mega-menu > div {
    top: calc(100% + 10px);
  }

  .mega-menu > div {
    left: 0;
    opacity: 0;
    z-index: -1;
    transition: 0.3s;
  }

  .mega-menu:hover > div {
    left: 0;
    top: 100%;
    opacity: 100;
    z-index: 2;
  }
}

.news-color {
  background-color: #5e6a71;
}

@media (max-width: 1024px) {
  .before-line::after {
    display: none;
  }
}

/* style for News box  */
.news-box img {
  filter: brightness(0.6);
}

.news-box:hover img {
  filter: brightness(1);
}

.news-box:hover {
  color: white;
}

/* line divider */
.line-divider::before {
  content: " ";
  position: absolute;
  width: calc(100% + 35px);
  height: 1px;
  background-color: rgb(162, 162, 162);
  left: -20px !important;
  top: 100%;
}

/* show phone menu */
.menu-links {
  transition: 0.3s;
  left: -100%;
}

@media (max-width: 1024px) {
  .mega-menu:hover .dropdown-menu {
    left: 0%;
  }

  .dropdown-menu {
    top: 0%;
    left: -100%;
    height: 100%;
    color: white;
    background-color: rgb(203, 203, 203);
    display: block;
    transition: 0.4s;
  }

  .dropdown-menu > div {
    position: absolute;
    top: 180px;
    margin: 0 50px;
  }
}

/* start video section */
@media (min-width: 1025px) {
  #video .video-section {
    height: 45vh;
    z-index: -10;
  }
}

/* start button hover */
.buttom-lg:container svg {
  transition: 0.3s;
}

.buttom-lg:container:hover svg {
  padding-left: 10px;
}

.buttom-lg:container:hover a {
  color: rgb(59 130 246);
}
/* end button hover */
/* .button > div .icon {
  left: calc(100%);
} */
</style>
<script>
import MianSlider from "@/components/MianSlider.vue";
import ManaGements from "@/components/ManaGements.vue";

export default {
  name: "HomeView",
  components: {
    MianSlider,
    ManaGements,
  },
  data() {
    return {
      updateScreenWidth: "",
      screenwidth: window.innerWidth,
      languageAR: "AR",
      languageEN: "EN",
      closeIcon: "x",
      company: "company",
      mcep: "mcep",
      menuItems: [
        {
          name: "Home",
          title: "Industrial Plan",
          links: [
            { name: "Market Environment", url: "#" },
            { name: "Market Environment", url: "#" },
            { name: "Market Environment", url: "#" },
          ],
        },
        {
          name: "About",
          title: "Industrial Plan",
          links: [
            { name: "Market Environment", url: "#" },
            { name: "Market Environment", url: "#" },
            { name: "Market Environment", url: "#" },
          ],
        },
      ],
      footerItems: [
        "about",
        "innovation & technology",
        "sustainability",
        "careers",
      ],
      loremIpsum:
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Non tempore accusamus sapiente veniam, quo ducimus blanditiis itaque ut, hic, aut perferendis delectus sequi! Voluptates sapiente nesciunt vitae non! Accusamus, obcaecati.",
      images: [],
      texts: ["ISO 14001", "ISO 45001", "ISO 9001"],
      currentIndex: 0,
      currentImage: "./assets/ISO/one.jpg",
      currentText: "IOS",
      currentISO: "ISO 14001",
    };
  },
  methods: {
    showMenu() {
      this.$refs.menuLinks.style.left = "0";
    },
    closeMenu() {
      this.$refs.menuLinks.style.left = "-100%";
    },
  },
};
</script>
