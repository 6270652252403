<template>
  <HeaderNav />
  <div class="" :dir="$i18n.locale === 'ar' ? 'rtl' : 'ltr'">
    <router-view />
  </div>
  <FooterBar />
</template>
<script>
import HeaderNav from "@/components/HeaderNav.vue";
import FooterBar from "@/components/FooterBar.vue";

export default {
  components: {
    HeaderNav,
    FooterBar,
  },
};
</script>
